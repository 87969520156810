import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import GalleryUserSearch from "./Data/GalleryUserSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "react-query";
import PushAPI from "./Data/PusyAPI";
import PushAllAPI from "./Data/PushAllAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const SendButton = styled(Button)`
  background-color: #2282e9;
  color: #fff;
`;

const ListButton = styled(Button)`
  background-color: #f0f9ff;
  color: #2282e9;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 22px;
`;

const UserSearchInput = styled.input`
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  width: 941px;
  height: 60px;
  outline: none;
  padding: 0px 20px;
  box-sizing: border-box;
`;

const UserBtn = styled.div`
  background-color: #f1f1f1;
  width: 151px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
`;

const Label = styled.div`
  color: #555555;
  font-weight: 500;
  margin-bottom: 12px;
`;

const TitleInput = styled.input`
  width: 1572px;
  height: 50px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  margin-bottom: 18px;
  outline: none;
  padding: 0px 20px;
  box-sizing: border-box;
`;

const ContentTextarea = styled.textarea`
  width: 1572px;
  height: 100px;
  border-radius: 6px;
  resize: none;
  margin-bottom: 18px;
  border: 1px solid #e3e3e3;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
`;

const CategorySelect = styled.select`
  width: 1572px;
  height: 50px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  appearance: none;
  outline: none;
  padding: 0px 20px;
  box-sizing: border-box;
`;

const SelectContainer = styled.div`
  position: relative;
  width: 1572px;
`;

const UserResults = styled.div`
  display: flex;
  border: 1px solid #e3e3e3;
  width: 1530px;
  height: 187px;
  overflow-y: scroll;
  padding: 20px;
  border-radius: 6px;
`;

const User = styled.div`
  background-color: #f0f9ff;
  border-radius: 50px;
  color: #2282e9;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 500;
  width: max-content;
  cursor: pointer;
  margin-right: 12px;
`;

const AllUsers = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 5px;
  margin: 10px 10px 10px 0;
  padding: 10px;
  width: max-content;
  background-color: #2282e9;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
`;

export default function GalleryNotificationSend() {
  type IdsList = { id: number };
  const { register, getValues, setValue, handleSubmit } = useForm();
  const location = useLocation();
  const galleryId = location.state.galleryId;

  const [selectedUsers, setSelectedUsers] = useState<IdsList[]>([]);
  const [userLists, setUserList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [isAllUser, setIsAllUser] = useState(false);

  function close() {
    setModal(false);
  }

  function onClick() {
    if (
      selectedUsers &&
      getValues("title") &&
      getValues("message") &&
      getValues("category")
    ) {
      setModal(true);
    } else {
      alert("모든 항목을 입력해주세요.");
    }
  }

  function callAll() {
    const input = "";
    GalleryUserSearch(input, galleryId)
      .then((r) => {
        setSelectedList(r.data.contents);
      })
      .catch(() => {
        alert("다시 시도하세요");
        window.location.reload();
      });
  }

  useEffect(() => {
    callAll();
  }, []);

  const onSubmitSearch = async (data: any) => {
    try {
      const response = await GalleryUserSearch(data.search, galleryId);

      const newSearchResults = response.data.contents;
      setValue("search", "");

      setUserList(newSearchResults);
    } catch (e) {
      alert("다시 시도하세요");
      window.location.reload();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmitSearch)();
    }
  };

  function clickUser(id: any) {
    let set = new Set(selectedUsers);

    if (set.has(id as never)) {
      set.delete(id as never);
      let temp = Array.from(set);
      setSelectedUsers(temp);
    } else {
      if (selectedUsers.length < 101) {
        let temp = selectedUsers.concat(id);
        setSelectedUsers(temp);
      } else {
        alert("100명을 초과할 수 없습니다!");
      }
    }
  }

  const [all, setALL] = useState(false);
  function selectAll() {
    // if (!all) {
    //   selectedUsers.splice(0);
    //   setValue("category", "TOPIC_NEW");
    // }
    // setALL(!all);
    if (all) {
      setIsAllUser(true);
    } else {
      selectedUsers.splice(0);
      setIsAllUser(false);
    }
    setALL((prev) => !prev);
  }

  async function CallAPI() {
    const idList = selectedUsers.map((item) => item.id);

    if (isAllUser) {
      const requestData = {
        ids: idList,
        title: getValues("title"),
        message: getValues("message"),
        category: getValues("category"),
      };
      await PushSendMutation.mutateAsync(requestData);
    } else {
      const requestDataWithoutIds = {
        title: getValues("title"),
        message: getValues("message"),
        // category: getValues("category"),
        category: "TOPIC_NEW",
      };

      await PushAllMutation.mutateAsync(requestDataWithoutIds);
    }
  }

  const PushSendMutation = useMutation(
    (requestData: any) => PushAPI(requestData),
    {
      onSuccess: () => {
        setIsLoading(false);
        alert("푸시메시지를 전송했습니다.");
        setModal(false);
        window.location.reload();
      },
      onError: () => {
        setIsLoading(false);
        alert("푸시 전송을 실패했습니다. 다시 시도하세요.");
        setModal(false);
      },
    }
  );

  const PushAllMutation = useMutation(
    (requestData: any) => PushAllAPI(requestData),
    {
      onSuccess: () => {
        setIsLoading(false);
        alert("전체 푸시를 전송했습니다.");
        window.location.reload();
      },
      onError: () => {
        setIsLoading(false);
        alert("푸시 전송을 실패했습니다. 다시 시도하세요.");
        setModal(false);
      },
    }
  );

  return (
    <Wrapper>
      {modal && <Modal close={close} CallAPI={CallAPI} isLoading={isLoading} />}
      <HeaderContainer>
        <Title>알림 전송 및 내역</Title>
        <ButtonContainer>
          <Link to="list" style={{ textDecoration: "none" }}>
            <ListButton>알림 내역 확인</ListButton>
          </Link>
          <SendButton onClick={onClick}>메세지 전송</SendButton>
        </ButtonContainer>
      </HeaderContainer>
      <Content>
        {!all && (
          <>
            <LabelContainer>
              <SubTitle>유저 검색</SubTitle>
              <div style={{ display: "flex", gap: "30px" }}>
                <div style={{ position: "relative" }}>
                  <UserSearchInput
                    type="text"
                    placeholder="닉네임이나 전화번호를 입력하세요"
                    onKeyDown={handleKeyDown}
                    {...register("search")}
                  />
                  <div onClick={handleSubmit(onSubmitSearch)}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        color: "#d1d1d1",
                        position: "absolute",
                        top: "18px",
                        right: "22px",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </div>
                </div>
                <UserBtn onClick={selectAll}>유저 전체 선택</UserBtn>
              </div>
            </LabelContainer>
            {userLists.length !== 0 && (
              <UserResults style={{ marginBottom: "20px" }}>
                {userLists &&
                  userLists.map((user: any) => {
                    let set = new Set(selectedUsers);

                    return (
                      <div key={user.id} onClick={() => clickUser(user)}>
                        {!set.has(user as never) && (
                          <User>{user.nickname}</User>
                        )}
                      </div>
                    );
                  })}
              </UserResults>
            )}
          </>
        )}

        <LabelContainer>
          <SubTitle>
            선택한 유저 {!all && selectedUsers.length + "/100"}
          </SubTitle>
          {all ? (
            <AllUsers onClick={selectAll}>전체 유저</AllUsers>
          ) : (
            <UserResults>
              {selectedUsers.length !== 0 &&
                selectedUsers.map((user: any) => {
                  return (
                    <div key={user.nickname} onClick={() => clickUser(user)}>
                      <User>{user.nickname}</User>
                    </div>
                  );
                })}
            </UserResults>
          )}
        </LabelContainer>
        <LabelContainer>
          <SubTitle>알림 메세지 입력</SubTitle>
          <Label>제목</Label>
          <TitleInput type="text" {...register("title")} />
          <Label>내용</Label>
          <ContentTextarea rows={2} maxLength={250} {...register("message")} />
          <Label>카테고리</Label>
          <SelectContainer>
            <CategorySelect {...register("category")}>
              <option value="NOTICES">공지 사항</option>
              <option value="ADVERTISEMENTS">광고</option>
            </CategorySelect>
            <FontAwesomeIcon
              icon={faPlay}
              style={{
                rotate: "90deg",
                color: "#a7a7a7",
                position: "absolute",
                top: "18px",
                right: "17px",
              }}
            />
          </SelectContainer>
        </LabelContainer>
      </Content>
    </Wrapper>
  );
}
